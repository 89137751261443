import { closeModal, openCaptchaModal, removeFullPage, togglePreloader } from './dom-helpers.js';
import { showResult, hidePagination } from './data-results.js';

function searchRequest({ query, pageNumber, token }) {

	removeFullPage();
	togglePreloader("block");

	const lang = document.getElementById('lang').value || "EN";

	const url = process.env.NODE_ENV === 'production'
		? 'php/search.php'
		: 'http://localhost:8000/search.php';

	const xhr = new XMLHttpRequest();
	xhr.open('POST', url, true);
	xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
	xhr.onload = function () {
		if (xhr.status === 200) {
			const data = JSON.parse(xhr.responseText);
			if (data.result === 'success') {
				togglePreloader("none");
				showResult(data.data);
				// if (pageNumber === 1) {
				// 	showPagination(data.data.totalPages, pageNumber);
				// }
			} else if (data.result === 'captcha-needed') {
				// searchParams.query = query;
				// searchParams.pageNumber = pageNumber;
				hidePagination();
				document.getElementById('search-query').value = query;
				document.getElementById('search-page-number').value = pageNumber;
				openCaptchaModal();
			}
		}
	};
	if (token) {
		const params = `q=${encodeURIComponent(query)}&page=${pageNumber}&lang=${lang}&g-recaptcha-response=${encodeURIComponent(token)}`;
		xhr.send(params);
	} else {
		const params = `q=${encodeURIComponent(query)}&page=${pageNumber}&lang=${lang}`;
		xhr.send(params);
	}
}


function sendRequest({ projectName, projectLink, comment, recaptcha }) {
	const xhr = new XMLHttpRequest();
	if (process.env.NODE_ENV === 'production') {
		xhr.open("POST", "php/save_data.php", true);
	} else {
		xhr.open("POST", "http://localhost:8000/save_data.php", true);
	}
	xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
	xhr.onreadystatechange = function () {
		if (this.readyState === XMLHttpRequest.DONE && this.status === 200) {
			const response = JSON.parse(this.responseText);
			if (response.success) {
				const modalWin = document.querySelector('.modal-win');
				modalWin.style.opacity = '0.5';
				const successMsg = document.createElement('p');
				successMsg.textContent = 'Created successfully';
				modalWin.appendChild(successMsg);
				const h3 = modalWin.querySelector('h3');
				const form = modalWin.querySelector('form');
				const discl = modalWin.querySelector('.discl');
				h3.style.display = 'none';
				form.style.display = 'none';
				discl.style.display = 'none';
				setTimeout(function () {
					modalWin.style.opacity = '1';
					successMsg.style.display = 'none';
					h3.style.display = 'block';
					form.style.display = 'block';
					discl.style.display = 'block';
					document.getElementById("project-name").value = "";
					document.getElementById("project-link").value = "";
					document.getElementById("project-comment").value = "";
					grecaptcha.reset(0);
					closeModal();
				}, 3000);
			} else {
				alert('CAPTCHA verification failed. Please try again.');
			}
		}
	}

	xhr.send(`project-name=${projectName}&project-link=${projectLink}&comment=${comment}&recaptcha=${recaptcha}`);
}

export { searchRequest, sendRequest };
