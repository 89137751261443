import { searchRequest, sendRequest } from './api-requests.js';
import { clearSearchResults, closeCaptchaModal } from './dom-helpers.js';
import { isValidUrl } from './utilities.js';

function handleSearch() {
	var search = document.getElementById("search");
	var searchTerm = search.value.toLowerCase().trim();
	if (searchTerm.length < 3) {
		const warning = document.createElement('div');
		warning.classList.add('search-alert');
		warning.innerHTML = "Search term must be at least 3 characters";
		document.querySelector(".search-input").appendChild(warning);
		setTimeout(function () {
			warning.remove();
		}, 3000);
		return;
	}
	if (searchTerm) {
		clearSearchResults();
		var warning = document.querySelector(".search-input div");
		if (warning) {
			warning.remove();
		}
		searchRequest({ query: searchTerm, pageNumber: 1 });
	} else {
		clearSearchResults();
	}
}

function handleSend(e) {
	e.preventDefault();
	const projectName = document.getElementById("project-name").value;
	const projectLink = document.getElementById("project-link").value;
	const comment = document.getElementById("project-comment").value;
	const recaptcha = grecaptcha.getResponse();
	if (!projectLink || !isValidUrl(projectLink)) {
		alert('Please enter a valid URL for the project link.');
		return;
	}

	sendRequest({ projectName, projectLink, comment, recaptcha })
}

function handleCaptcha(token) {
	grecaptcha.reset(1);
	closeCaptchaModal();
	const query = document.getElementById("search-query");
	const pageNumber = document.getElementById("search-page-number");
	searchRequest({ query: query.value, pageNumber: pageNumber.value, token });
}

export { handleSearch, handleSend, handleCaptcha };
