function toggleFullPage() {
	var searchResults = document.querySelector('.search-results');
	var ul = document.querySelector('ul#result');
	var searchBlock = document.querySelector('.search-block');
	if (!ul.hasChildNodes()) {
		searchBlock.classList.add('fullpage');
		if (navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1) {
			searchBlock.style.height = window.innerHeight + 'px';
		}
	} else {
		searchBlock.classList.remove('fullpage');
		searchBlock.style.height = 'auto';
	}
}

function removeFullPage() {
	var searchBlock = document.querySelector('.search-block');
	searchBlock.classList.remove('fullpage');
	searchBlock.style.height = 'auto';
}

function togglePreloader(visibility) {
	var preloader = document.querySelector('#preloader');
	preloader.style.display = visibility;
}

function clearSearchResults() {
	var ul = document.querySelector('ul#result');
	while (ul.firstChild) {
		ul.removeChild(ul.firstChild);
	}
	// toggleFullPage();
}

function openModal() {
	const modalSuggest = document.getElementById("modal-suggest");
	modalSuggest.style.display = 'grid';
}

function closeModal() {
	const modalSuggest = document.getElementById("modal-suggest");
	modalSuggest.style.display = "none";
}

function openCaptchaModal() {
	const modalCaptcha = document.getElementById("modal-captcha");
	modalCaptcha.style.display = 'grid';
}

function closeCaptchaModal() {
	const modalCaptcha = document.getElementById("modal-captcha");
	modalCaptcha.style.display = "none";
}


export { toggleFullPage, removeFullPage, clearSearchResults, 
	openModal, closeModal, openCaptchaModal, closeCaptchaModal,
	togglePreloader };
