import { searchRequest } from './api-requests.js';
import { handleSearch } from './tasks.js';
import cloudSvg from './svg/cloud.js';

function showResult(data) {
	var ul = document.querySelector('ul#result');
	var items = [];

	hidePagination();

	if (data.companies.length === 0) {
		ul.innerHTML = `<p class="not-results">Nothing found.</p>`;
	} else {
		var startIndex = (data.currentPage - 1) * data.itemsPerPage;
		var endIndex = startIndex + data.companies.length;
		data.companies.forEach(function(val) {
			const categories = val.category.split(',')
					.map(category => category.trim())
					.filter(category => category !== '')
					.map(category => `<span class="tag">${category}</span>`)
					.join('');
			items.push(`<li>
					<div class="prj-block">
							<a href="${val.website}" target="_blank" class="link-title"><img class="favicon" onerror=
							"javascript: this.style='display:none;'" src="${val.favicon || cloudSvg}" alt=""> ${val.name}</a>
							<p>${val.description}</p>
							<a class="link-bottom" target="_blank" href="${val.website}">${val.website}</a>
							<div class="tags-list">
									${categories}
									${val.business_model ? `<span class="tag-model">${val.business_model}</span>` : ''}
							</div>
					</div>
					<img src="${val.image}" alt="" class="og-image">
			</li>`);
	})
	
	
	
		ul.innerHTML = items.join("");
	}

	var ulscroll = ul.offsetTop;
	window.scrollTo({ top: ulscroll, behavior: 'smooth'});

	document.querySelectorAll('span.tag').forEach(function(tag) {
		tag.addEventListener('click', function() {
			var searchText = this.innerText;
			document.getElementById('search').value = searchText;
			handleSearch();
		});
	});

	showPagination(data.totalPages, data.currentPage);
}

function hidePagination() {
	const searchResultsEl = document.querySelector(".search-results");
	const previousPaginationEl = searchResultsEl.querySelector(".pagination");
	if (previousPaginationEl) {
		previousPaginationEl.remove();
	}
}

function showPagination(totalPages, currentPage) {
	const searchResultsEl = document.querySelector(".search-results");

	if (totalPages === 1) {
		return;
	}

	const paginationEl = document.createElement("div");
	paginationEl.classList.add("pagination");

	for (let i = 1; i <= totalPages; i++) {
		const linkEl = document.createElement("a");
		linkEl.href = "#";
		linkEl.textContent = i;

		if (i === currentPage) {
			linkEl.classList.add("active");
		}

		linkEl.addEventListener("click", function(e) {
			e.preventDefault();
			const searchEl = document.getElementById("search");
			searchRequest({ query: searchEl.value, pageNumber: i });
			paginationEl.querySelectorAll("a").forEach(link => {
				link.classList.remove("active");
			});
			linkEl.classList.add("active");
		});
		paginationEl.appendChild(linkEl);
	}
	searchResultsEl.appendChild(paginationEl);
}


export { showResult, hidePagination };
