import { handleSearch, handleSend, handleCaptcha } from './tasks.js';
import { openModal, closeModal } from './dom-helpers.js';

document.addEventListener("DOMContentLoaded", function() {
    var search = document.getElementById("search");
    var searchButton = document.getElementById("search-button");

    search.addEventListener("keyup", function(event) {
        if (event.key === 'Enter') {
            handleSearch();
        }
    });

    searchButton.addEventListener("click", handleSearch);

    var searchAssets = document.querySelectorAll(".search-assets span");
    var searchInput = document.querySelector("#search");

    searchAssets.forEach(function(asset) {
        asset.addEventListener("click", function() {
            searchInput.value = this.innerText;
        });
    });

    document.querySelector('.footer-block button').addEventListener('click', openModal);
    document.querySelector('.submit-project button').addEventListener('click', openModal);
    document.querySelector('.modal-win .before-d').addEventListener('click', handleSend);
    window.handleCaptcha = handleCaptcha;

    document.addEventListener("click", function(event) {
        const modal = document.getElementById("modal-suggest");
        if (event.target === modal) {
            closeModal();
        }
    });
});
